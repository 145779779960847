.arrow-progress-bar-wrapper {
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
}

.arrow-progress-bar-title {
  width: 100%;
  height: 30px;
  z-index: -2;
  white-space: nowrap;
  overflow: hidden;
}

.arrow-progress-bar-content {
  display: flex;
  width: 100%;
  white-space: nowrap;
}

.arrow-progress-bar-title div:first-child {
  margin-left: -2%;
}

.arrow-progress-bar-avatar {
  margin: 0;
  height: 48px;
  width: calc(33% + 7px);
  position: relative;
  display: inline-block;
  text-align: left;
  box-shadow: inset 0px 3px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 6px;
  border-top: none;
  border-bottom: none;
  &:first-child {
    width: 31%;
  }
}

.arrow-progress-bar-item {
  margin: 0;
  margin-left: 0.5%;
  height: 30px;
  width: calc(33% + 7px);
  flex-basis: calc(33% + 7px);
  position: relative;
  display: inline-block;
  text-align: left;
  padding-left: 12px;
  font-size: 13px;
  line-height: 30px;
  transition: all 0.8s;

  &.selected {
    &::before,
    &::after {
      background: linear-gradient(to right, white, theme('colors.theme'));
    }
  }
}

.arrow-progress-bar-item::before,
.arrow-progress-bar-item::after {
  content: '';
  position: absolute;
  transition: all 0.8s;
  z-index: -1;
}

.arrow-progress-bar-item::before {
  height: 50%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0 0 0 / 20%);
  -webkit-transform: skew(45deg);
  -moz-transform: skew(45deg);
  transform: skew(45deg);
}

.arrow-progress-bar-item::after {
  height: 50%;
  width: 100%;
  top: 50%;
  left: 0;
  background: rgba(0 0 0 / 20%);
  -webkit-transform: skew(45deg);
  -moz-transform: skew(45deg);
  transform: skew(-45deg);
}
