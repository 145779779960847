@import 'https://fonts.googleapis.com/earlyaccess/notosanskr.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .h-screen {
    height: 100vh;
    height: -webkit-fill-available;
  }

  .bounce-up {
    animation: bounce-up 1s ease-in-out;
  }

  @keyframes bounce-up {
    0% {
      bottom: -100vh;
    }

    100% {
      bottom: 0;
    }
  }
}

* {
  font-family: 'Noto Sans KR', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  color-scheme: only light;
}

body {
  touch-action: none;
}

:root {
  color-scheme: only light;
}

.react-modal-sheet-content {
  overflow-y: auto !important;
}
