.unsupported-container {
  height: 100vh;

  h1 {
    position: relative;
    display: block;
    margin: 0 auto;
    width: auto;
    height: 35px;
    top: 70px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #ff2929;
    text-align: center;
  }

  img {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 400px;
    top: 135px;
  }

  p {
    position: relative;
    display: block;
    margin: 0 auto;
    width: auto;
    height: 72px;
    top: 170px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
  }

  span {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 238px;
    height: 72px;
    top: 220px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  a {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 238px;
    height: 72px;
    top: 220px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  a:hover {
    color: coral;
  }
}
