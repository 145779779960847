.login-page-wrapper {
  text-align: center;
  height: 100%;

  .tooltip-box {
    position: relative;
    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 0;
      height: 0;
      border-top: 6px solid #1f1b4f;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
  }
}
.btn {
  position: relative;
  &:active {
    top: 2px;
  }
}
.my-login-modal {
  .mantine-Paper-root.mantine-Modal-content {
    height: 100% !important;
  }
}
