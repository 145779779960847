.container-wrapper {
  height: 100dvh;
  overflow-y: auto;
  min-height: -webkit-fill-available;
}

.container-tab-wrapper {
  height: 100dvh;
  overflow-y: hidden;
  min-height: -webkit-fill-available;
}
